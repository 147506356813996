import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Collapse, CircularProgress, IconButton, Button, Drawer } from '@mui/material';
import { ChevronDown, ChevronRight, Plus } from 'lucide-react';
import { styled } from '@mui/system';
import { API_URL } from '../constants/AppConstants';
import zIndex from '@mui/material/styles/zIndex';
import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const StyledDrawer = styled(Drawer)(({ isMobile }) => ({
  width: isMobile ? '100%' : '250px',
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: isMobile ? '70%' : '250px',
    color: '#fff',
    height: '100vh',
    backgroundColor: '#1a1a2e',
    border: 'none',
    boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
    position: 'fixed',
    left: isMobile ? 0 : 80,
    display: 'flex',
    flexDirection: 'column',
    // height: '100%',
    overflow: 'hidden',
    margin: isMobile ? 0 : undefined,
    padding: isMobile ? 0 : undefined,
  },
}));

const ScrollableBox = styled(Box)(({ theme }) => ({
  width: '250px',
  height: '100vh',
  bgcolor: '#1a1a2e',
  color: '#fff',
  padding: '16px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(255, 255, 255, 0.5)',
  },
  '@media (max-width: 768px)': {
    position: 'fixed',
    zIndex: '1000',
    paddingLeft: '50px'
  },
}));
const StyledButton = styled(Button)({
  color: '#fff',
  background: 'linear-gradient(90deg, #9900FF 0%, #737373 100%)',
  marginRight: '40px',
  opacity: 1,
  '&:hover': {
    background: 'linear-gradient(90deg, #B266FF 0%, #8C8C8C 100%)',
    opacity: 1,
  },
});
const ScrollableContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 56px)', // Subtract footer height
  marginTop: '10px',
  overflow: 'hidden',
  marginBottom: '20px' // Remove scroll from wrapper
});
const ContentArea = styled(Box)({
  flex: 1,
  overflowY: 'auto',
  marginBottom: '40px',
  padding: '16px',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f0f0f0',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#c0c0c0',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#a0a0a0',
  },
});
const FixedFooter = styled(Box)({
  backgroundColor: '#1a1a2e',
  padding: '16px',
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  bottom: 5,
  left: 0,
  right: 0,
  width: '100%',
  zIndex: 1
});
const CategoryList = styled(List)({
  padding: 0,
  '& > div': {
    marginBottom: '8px',
  },
});

const CustomizableSidebar = ({ onAddChart, onClose }) => {
  const [metrics, setMetrics] = useState({});
  const [loading, setLoading] = useState(true);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [hoveredMetric, setHoveredMetric] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState(null);
  const fnbData = JSON.parse(localStorage.getItem('fnb'));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const addMetricToCarousel = (selectedMetric) => {

    onAddChart(selectedMetric)
    setSelectedMetric(selectedMetric);
  }
  const handleCloseClick = () => {
    onClose();
  };
  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const user_id = fnbData.user_id;


        const storedMetrics = fnbData.metricsData;
        if (storedMetrics) {
          const parsedMetrics = JSON.parse(storedMetrics);
          setMetrics(parsedMetrics);

          const initialExpanded = Object.keys(parsedMetrics).reduce((acc, category) => {
            acc[category] = false;
            return acc;
          }, {});
          setExpandedCategories(initialExpanded);
          setLoading(false);
        } else {
          const response = await fetch(`${API_URL}/listMetrics`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              user_id: user_id,
            }),
          });
          const data = await response.json();

          setMetrics(data.Data);

          const updatedFnbData = {
            ...fnbData,
            metricsData: JSON.stringify(data.Data)
          };
          localStorage.setItem('fnb', JSON.stringify(updatedFnbData));

          const initialExpanded = Object.keys(data.Data).reduce((acc, category) => {
            acc[category] = false;
            return acc;
          }, {});
          setExpandedCategories(initialExpanded);
          setLoading(false);
        }
      } catch (error) {

        setLoading(false);
      }
    };

    fetchMetrics();
  }, []);

  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };
  // const addSalesByMenuCategory = () => {
  //   const newChart = generateSalesByMenuCategoryData(); 
  //   onAddChart(newChart);
  // };

  const renderMetrics = (category, metricsList) => (
    <Collapse in={expandedCategories[category]}>
      <List disablePadding>
        {metricsList.map((metric) => {



          return (
            <ListItem
              key={metric}
              onMouseEnter={() => setHoveredMetric(metric)}
              onMouseLeave={() => setHoveredMetric(null)}
              sx={{
                pl: 4,
                py: 0.5,
                '&:hover': { bgcolor: 'rgba(255,255,255,0.1)' },
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              {metric.enabled !== true ? (
                <ListItemText
                  primary={metric.MetricDisplayName}
                  primaryTypographyProps={{
                    variant: 'body2',
                    sx: { color: '#6b6767', opacity: 0.5 }
                  }} />) :
                (
                  <ListItemText
                    primary={metric.MetricDisplayName}
                    primaryTypographyProps={{
                      variant: 'body2',
                      sx: { color: '#b8b8b8' }
                    }} />
                )

              }


              {hoveredMetric === metric && category === 'Advanced' ? (
                <IconButton
                  size="small"
                  onClick={() => {
                    addMetricToCarousel(metric);
                  }}
                  sx={{ color: 'violet' }}
                >
                  <RemoveRedEyeRoundedIcon size={10} />
                </IconButton>
              ) : hoveredMetric === metric & metric.enabled === true ?
                (
                  <IconButton
                    size="small"
                    onClick={() => {
                      addMetricToCarousel(metric);
                    }}
                    sx={{ color: 'white' }}
                  >
                    <Plus size={16} />
                  </IconButton>
                ) : metric.enabled === true ?
                  (

                    <IconButton
                      size="small"
                      onClick={() => {
                        addMetricToCarousel(metric);
                      }}
                      sx={{ color: 'white' }}
                    >
                    </IconButton>


                  ) : null}

            </ListItem>
          );
        })}
      </List>
    </Collapse>
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    // <ScrollableBox
    //   sx={{
    //     width: '250px',
    //     height: '100vh',
    //     bgcolor: '#1a1a2e',
    //   }}>
    <StyledDrawer
      variant="persistent"
      anchor="left"
      open={true}
      isMobile={isMobile}
    >
      <ScrollableContent>
        <ContentArea>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', ml: 2 }}>
            Add More Metrics
          </Typography>
          <CategoryList>

            {Object.entries(metrics).map(([category, metricsList]) => (
              <Box key={category} sx={{ mb: 2, ml: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    '&:hover': { bgcolor: 'rgba(255,255,255,0.1)' }
                  }}
                  onClick={() => toggleCategory(category)}
                >
                  {expandedCategories[category] ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', ml: 1 }}>
                    {category}
                  </Typography>
                </Box>

                {renderMetrics(category, metricsList)}
              </Box>
            ))}
          </CategoryList>
        </ContentArea>
      </ScrollableContent>
      {/* </ScrollableBox> */}
      <FixedFooter>
        <StyledButton onClick={handleCloseClick} color="primary">
          Close
        </StyledButton>
      </FixedFooter>

    </StyledDrawer >
  );
};

export default CustomizableSidebar;