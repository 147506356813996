import React, { useState, useEffect, } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logoImage from '../assets/White-icon.png';
import looqupImage from '../assets/Looqup.png';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../constants/AppConstants';
const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(118.12deg, #131B34 0%, #131B34 41.39%, #5B0CA2 94.83%, #9900FF 101.97%);
  overflow: hidden;
`;

const LoginCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  height: 80%;
  max-height: 600px;
  position: relative;

  @media (max-width: 768px) {
    height: auto;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50px;
  bottom: 50px;
  width: 500px;
  height: 500px;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(153, 0, 255, 0.4) 0%,
      rgba(122, 0, 204, 0.3) 50%,
      rgba(92, 0, 153, 0.1) 100%
    );
    border-radius: 50%;
    filter: blur(60px);
  }

  &::after {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(153, 0, 255, 0.6) 0%,
      rgba(122, 0, 204, 0.4) 50%,
      transparent 100%
    );
    border-radius: 50%;
    filter: blur(40px);
  }

  @media (max-width: 768px) {
    width: 300px;
    height: 300px;  /* Adjust size for tablets */
    left: 20px;
    bottom: 20px;
  }

  @media (max-width: 480px) {
    display: none;
  }
`;
const Logo = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
  background: url(${logoImage}) no-repeat center/contain;
  z-index: 2;

  @media (max-width: 1200px) {
    width: 200px;
    height: 200px;
  }
`;

const LoginForm = styled.div`
  width: 60%;
  max-width: 400px;
  height: auto;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 30px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  position: absolute;
  right: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    position: static;
    width: 70%;
    max-width: none;
  }

  @media (max-width: 480px) {
    padding: 20px;
    width: 100%;
  }
`;

const Title = styled.img`
  width: 150px;
  height: auto;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 120px;
  }
`;

const Label = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  color: white;
  font-size: 14px;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 15px;
  border-radius: 20px;
  margin-top: 5px;
  border: 2px solid #6A27AD;
  background: transparent;
  color: white;
  outline: none;

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const Options = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  width: 100%;
  font-size: 12px;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const RememberMe = styled.div`
  display: flex;
  align-items: center;
  color: white;
`;

const ForgotPassword = styled.a`
  color: white;
  margin-left: 10px;

  @media (max-width: 480px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 20px;
  background: linear-gradient(to right, #6A27AD, #391B7C);
  color: white;
  cursor: pointer;
  margin: 20px 0 0;
  font-size: 14px;
  opacity: ${props => props.disabled ? 0.6 : 1};
`;
const SECRET_KEY = "Y29uc3VtZXJfZm5iX3BvYw==";
const Login = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // const hashedPassword = "202389ea952562dabfeb53d453699e548455baf93004eb5233370155e57a4519"
  const hashedPassword = CryptoJS.SHA256(password + SECRET_KEY).toString();
  console.log('Hashed Password:', hashedPassword);

  useEffect(() => {
    if (localStorage.getItem('fnb')) {
      navigate('/talktodata');
    }
  }, [navigate]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const loginData = {
      username: username,
      password: hashedPassword,
      secret_key: SECRET_KEY,
    };

    try {
      const response = await fetch(`${API_URL}/signIn`, {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });

      if (response.ok) {
        {
          const result = await response.json();
          toast.success(result.message || 'Login successful');
          const fnb = {
            accessToken: result.access_token,
            firstName: result.access_token_decoded.firstName.trim(),
            insightId: String(result.access_token_decoded.insights[0].InsightId),
            user_role_id: String(result.access_token_decoded.userRoleId),
            role_id: String(result.access_token_decoded.userRoles[0]),
            user_id: result.access_token_decoded.userId,
            userRole: result.access_token_decoded.userRoles[0],
            latestDataDate: result.latestDataDate, // Extracting from the main result object
        };

          setTimeout(() => {
            localStorage.setItem('fnb', JSON.stringify(fnb));
            navigate('/talktodata');
          }, 2000);
        }
      } else {
        const errorResult = await response.json();
        toast.error(errorResult.message || 'Login failed'); // Display error message
      }
    } catch (error) {
      toast.error('An error occurred'); // Display network error message
    }
    finally {
      setIsLoading(false); 
    }
  };

  return (
    <Container>
      <LoginCard>
        {!isMobile && (
          <LogoContainer>
            <Logo />
          </LogoContainer>
        )}
        <LoginForm>
          <Title src={looqupImage} alt="Looqup Logo" />
          <p style={{ textAlign: 'center', color: "white", fontSize: '14px' }}>ILLUMINATE YOUR DATA</p>
          <form onSubmit={handleSubmit}>
            <Label>
              Username
              <Input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Label>
            <Label>
              Password
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Label>
            <Options>
              <RememberMe>
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                /> Remember me
              </RememberMe>
              <ForgotPassword href="#">Forgot password?</ForgotPassword>
            </Options>
            <Button type="submit" disabled={isLoading}>SIGN IN</Button>
          </form>
        </LoginForm>
      </LoginCard>
      <ToastContainer /> {/* Toast container for displaying messages */}
    </Container>
  );
};

export default Login;