import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Button, IconButton, InputBase, CircularProgress, Avatar, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ChevronIcon from '../assets/Chevron.png';
import AttachIcon from '../assets/Attach.png';
import SpeakIcon from '../assets/Speak.png';
import { IoIosShareAlt } from "react-icons/io";
import { FaMousePointer } from "react-icons/fa";
import ReplayIcon from '@mui/icons-material/Replay';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { FaRegEdit } from "react-icons/fa";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Sidebar from '../components/Sidebar';
import { API_URL } from '../constants/AppConstants';
import logoSvg from '../assets/logo.svg';
import userLogo from '../assets/user-logo.png';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { TrendingUp } from '@mui/icons-material';
import RelatedIcon from '../assets/Related.png'
import ThingsToDoNext from '../assets/ThingsToDo.png'
const StyledChatBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  background: 'rgba(69, 18, 129, 0.3)',
  borderRadius: '8px',
  height: 'calc(100vh - 240px)', // Reduced height 
  [theme.breakpoints.down('sm')]: {
    height: '32vh', // Slightly reduced mobile height
    minHeight: '220px',
    maxHeight: '280px',
   
    padding: theme.spacing(1),
  },
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '2px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(255, 255, 255, 0.5)',
  },
}));

const SearchBar = ({ query, setQuery, handleSearch, loading }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: 'linear-gradient(135deg, #524b71 20%, #726a8d 80%)',
        borderRadius: 3,
        p: isMobile ? 1 : 2,
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',

      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: isMobile ? 1 : 2 }}>
        <InputBase
          sx={{
            color: 'white',
            fontSize: isMobile ? '0.8rem' : '1rem',
            mb: isMobile ? 1 : 2
          }}
          placeholder="What were our top-performing sales channels?"
          inputProps={{ 'aria-label': 'search' }}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Button
              startIcon={<Box component="img" src={AttachIcon} alt="Attach" sx={{ width: isMobile ? 16 : 24, height: isMobile ? 16 : 24 }} />}
              sx={{ color: 'white', textTransform: 'none', mr: 1, fontSize: isMobile ? '0.7rem' : '1rem' }}
            >
              Attach
            </Button>
            <Button
              startIcon={<Box component="img" src={SpeakIcon} alt="Speak" sx={{ width: isMobile ? 16 : 24, height: isMobile ? 16 : 24 }} />}
              sx={{ color: 'white', textTransform: 'none', fontSize: isMobile ? '0.7rem' : '1rem' }}
            >
              Speak
            </Button>
          </Box>
          <IconButton 
         sx={{ color: 'white', p: isMobile ? 0.5 : 1 }} 
         onClick={() => {
        console.log('IconButton clicked');
         handleSearch();
          }} 
         disabled={loading}>
         {loading ? (
              <CircularProgress size={isMobile ? 16 : 24} color="inherit" />
            ) : (
              <Box component="img" src={ChevronIcon} alt="Search" sx={{ width: isMobile ? 16 : 24, height: isMobile ? 16 : 24 }} />
            )}
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

const ChatMessage = ({ isUser, content }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isUser ? 'flex-end' : 'flex-start',
        mb: isMobile ? 0.5 : 0.75, // Reduced space between messages
      }}
    >
      {!isUser && (
        <Avatar
          sx={{
            bgcolor: '#5c4d80',
            mr: 1,
            width: isMobile ? 24 : 32,
            height: isMobile ? 24 : 32,
          }}
        >
          <img src={logoSvg} alt="Logo" style={{ width: '100%', height: '100%', padding: '2px' }} />
        </Avatar>
      )}
      <Box
        sx={{
          maxWidth: '75%',
          p: isMobile ? '4px 8px' : '6px 10px', // Reduced padding
          borderRadius: 1.5,
          bgcolor: isUser ? '#726a8d' : '#5c4d80',
          fontSize: isMobile ? '0.7rem' : '0.875rem', // Smaller font
          '& p': {
            fontSize: isMobile ? '0.7rem' : '0.875rem',
            margin: '2px 0', // Reduced margin
            lineHeight: 1.3, // Reduced line height
          },
          '& table': {
            width: '100%',
            borderCollapse: 'collapse',
            fontSize: isMobile ? '0.65rem' : '0.8rem',
          },
          '& th, & td': {
            border: '1px solid #ccc',
            padding: isMobile ? '2px 4px' : '3px 6px', // Reduced padding
            fontSize: isMobile ? '0.65rem' : '0.8rem',
          },
          '& code': {
            fontSize: isMobile ? '0.65rem' : '0.8rem',
          },
          '& ul, & ol': {
            fontSize: isMobile ? '0.7rem' : '0.875rem',
            paddingLeft: isMobile ? '0.8rem' : '1.2rem', // Reduced padding
            margin: '2px 0', // Reduced margin
          },
          '& h1, & h2, & h3, & h4, & h5, & h6': {
            fontSize: isMobile ? '0.75rem' : '0.9rem',
            margin: '3px 0', // Reduced margin
            lineHeight: 1.3,
          },
          '& blockquote': {
            margin: '3px 0',
            paddingLeft: '8px',
            borderLeft: '2px solid rgba(255,255,255,0.3)',
          }
        }}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {content}
        </ReactMarkdown>
      </Box>
      {isUser && (
        <Avatar
          sx={{
            bgcolor: '#726a8d',
            ml: 1,
            width: isMobile ? 24 : 32,
            height: isMobile ? 24 : 32,
          }}
        >
          <img src={userLogo} alt="Logo" style={{ width: '100%', height: '100%', padding: '2px' }} />
        </Avatar>
      )}
    </Box>
  );
};

const RelatedQuestion = ({ question, onAskQuestion }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 0.5 }}>
      <Typography variant="body2" sx={{ color: 'white', flex: 1, fontSize: isMobile ? '0.7rem' : '0.8rem' }}>
        {question}
      </Typography>
      <IconButton size="small" sx={{ color: 'white' }} onClick={() => onAskQuestion(question)}>
        <Box component="span" sx={{ fontSize: isMobile ? '1rem' : '1.5rem' }}>+</Box>
      </IconButton>
    </Box>
  );
};

const MiddleSidebar = ({ isVisible, getLastQueryAndResponse }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const handleWaClick = () => {
    const { lastQuery, lastResponse } = getLastQueryAndResponse();
    const phoneNumber = '+16593336699';
    const message = `Last Query: ${lastQuery}\n\nResponse: ${lastResponse}`;
    const encodedMessage = encodeURIComponent(message);
    const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(url, '_blank'); 
  };

  const icons = [
    { label: 'Share to team', icon: <IoIosShareAlt />, onClick: handleWaClick },
    { label: 'Create Broadcast', icon: <FaMousePointer /> },
    { label: 'Regenerate Looqup', icon: <ReplayIcon /> },
    { label: 'Bad Answer', icon: <ThumbDownIcon /> },
    { label: 'Edit Looqup', icon: <FaRegEdit /> },
    { label: 'Copy Answer', icon: <ContentCopyIcon /> },
  ];

  return (
    <Box sx={{
      width: isVisible ? (isMobile ? '40px' : '70px') : '0',
      overflow: 'hidden',
      transition: 'width 0.3s ease-in-out',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderRadius: isMobile ? '15px' : '30px',
      padding: isVisible ? (isMobile ? '10px 5px' : '20px 10px') : '0',
      background: 'linear-gradient(135deg, #524b71 20%, #726a8d 80%)',
      height: 'auto',
      mt: isMobile ? 1 : 2,
    }}>
      {icons.map(({ label, icon, onClick }, index) => (
        <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: isMobile ? 1 : 2 }}>
          <IconButton 
            sx={{ color: 'white', fontSize: 'inherit', padding: isMobile ? '4px' : '5px' }}
            onClick={onClick}
          >
            <Box sx={{ fontSize: isMobile ? '1.2rem' : '1.5rem' }}>
              {icon}
            </Box>
          </IconButton>
          {!isMobile && (
            <Typography variant="caption" sx={{ color: 'white', textAlign: 'center', fontSize: '0.6rem', mt: 0.5 }}>
              {label}
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};


const QuestionItem = ({ question, onAskQuestion }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 0.5 }}>
      <Typography variant="body2" sx={{ color: 'white', flex: 1, fontSize: isMobile ? '0.7rem' : '0.8rem' }}>
        {question}
      </Typography>
      <IconButton size="small" sx={{ color: 'white' }} onClick={() => onAskQuestion(question)}>
        <Box component="span" sx={{ fontSize: isMobile ? '1rem' : '1.5rem' }}>+</Box>
      </IconButton>
    </Box>
  );
};



const AnswerPage = () => {
  const location = useLocation();
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [isMiddleSidebarVisible, setIsMiddleSidebarVisible] = useState(false);
  const [relatedQuestions, setRelatedQuestions] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  
  const chatEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  const fetchSimilarQuestions = async (queryText) => {
    try {
      const response = await fetch(`${API_URL}/generate_similar_questions`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: queryText }),
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setRelatedQuestions(data.similar_questions || []);
    } catch (error) {
      console.error('Error fetching similar questions:', error);
      setRelatedQuestions([]);
    }
  };

  const handleNavigate = () => {
    navigate('/reports')
  }
  const handleWaClick = () => {
    const phoneNumber = '+16593336699';
    const message = "Hello, can we have a repeat on the order for last week? "
    const encodedMessage = encodeURIComponent(message);
    const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(url, '_blank'); // Open in a new tab
  };

  const trendingQuestions = [
    "What are some pairs of items that is best sold together? Explain with reason",
    "Show the top 5 fast selling items of last month and the revenue generated in a table format",
    "What are the spending patterns seen in customers who buy in Rice Option category?"
  ];

  const thingsToDoNext = [
    { text: "Generate the Financial Report for this month", onClick: handleNavigate },
    { text: "Talk to your top vendor on WhatsApp", onClick: handleWaClick },
    { text: "View Product Mix for next 12 months" }
  ];


  useEffect(() => {
    if (location.state?.question && location.state?.answer) {
      setChatHistory([
        { isUser: true, content: location.state.question },
        { isUser: false, content: location.state.answer },
      ]);
      fetchSimilarQuestions(location.state.question);
    }
  }, [location.state]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleSearch = async (searchQuery = null) => {
    const finalQuery = searchQuery || query;

    if (!finalQuery) {
      console.error('No query to send.');
      return;
    }

    setLoading(true);
    try {
      const [searchResponse, similarQuestionsResponse] = await Promise.all([ fetch(`${API_URL}/lang_query`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: finalQuery }),
      }),
      fetch(`${API_URL}/generate_similar_questions`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: finalQuery }),
      })
    ]);

    if (!searchResponse.ok || !similarQuestionsResponse.ok) {
      throw new Error('One or more API calls failed');
    }

    const [searchData, similarQuestionsData] = await Promise.all([
      searchResponse.json(),
      similarQuestionsResponse.json()
    ]);

      if (searchData.response) {
        setChatHistory(prevHistory => [
          ...prevHistory,
          { isUser: true, content: finalQuery },
          { isUser: false, content: searchData.response },
        ]);
        setQuery('');
      }
      setRelatedQuestions(similarQuestionsData.similar_questions || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const onAskQuestion = (newQuery) => {
    handleSearch(newQuery);  // Pass the related question as the query
  };
  const toggleMiddleSidebar = () => {
    setIsMiddleSidebarVisible(!isMiddleSidebarVisible);
  };

  const getLastQueryAndResponse = () => {
    if (chatHistory.length >= 2) {
      const lastUserMessage = chatHistory[chatHistory.length - 2];
      const lastBotMessage = chatHistory[chatHistory.length - 1];
      return {
        lastQuery: lastUserMessage.isUser ? lastUserMessage.content : '',
        lastResponse: !lastBotMessage.isUser ? lastBotMessage.content : ''
      };
    }
    return { lastQuery: '', lastResponse: '' };
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    if (location.state?.question && location.state?.answer) {
      // If we have a question and answer, only show those
      setChatHistory([
        { isUser: true, content: location.state.question },
        { isUser: false, content: location.state.answer }
      ]);
    } else {
      // Only show welcome message if no question/answer in navigation state
      setChatHistory([{
        isUser: false,
        content: `👋 Hi! I'm your data assistant. Ask me anything about your business data or try one of the suggested questions on the right.`
      }]);
    }
  }, [location.state]);
  return (
    <Box sx={{ display: 'flex', minHeight: '100vh',overflow: 'hidden'  }}>
      <Sidebar />
      <Box sx={{
        flexGrow: 1,
        bgcolor: '#1e1e2e',
        background: 'linear-gradient(118.12deg, #131B34 0%, #131B34 41.39%, #5B0CA2 94.83%, #9900FF 101.97%)',
        minHeight: '100vh',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        p: isMobile ? 1.5 : 2,
        overflow: 'hidden'
      }}>
        {/* Top Navigation */}
        {/* <Box sx={{ 
          display: 'flex', 
          justifyContent: 'flex-end', 
          mb: isMobile ? 1 : 1.5,
          gap: 1
        }}>
          <IconButton 
            sx={{ color: 'white' }} 
            onClick={() => navigate('/talktodata')}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton sx={{ color: 'white' }}>
            <DashboardIcon />
          </IconButton>
        </Box> */}

        {/* Main Content Area */}
        <Box sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          height: isMobile ? 'auto' : 'calc(100vh - 80px)',
          gap: 2,
          flexGrow: 1,
        }}>
          {/* Left Section: Chat Area */}
          <Box sx={{
            width: isMobile ? '100%' : '70%',
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
          }}>
            {/* Chat Container */}
            <Box sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5,
            }}>
              {/* Header */}
              <Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Ensures left and right alignment
    height: isMobile ? '32px' : '40px', // Adjust height for mobile
  }}
>
  {/* Left: FirstName's Looqup */}
  <Typography
    variant="h4"
    sx={{
      fontWeight: 'bold',
      fontSize: {
        xs: '1rem',
        sm: '1.5rem',
        md: '2rem',
        lg: '2.5rem',
      },
      background: 'linear-gradient(45deg, #e879f9, #8b5cf6)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    }}
  >
    {`Talk To Your Data`}
  </Typography>

  {/* Right: MoreHorizIcon */}
  <IconButton
    onClick={toggleMiddleSidebar}
    sx={{
      color: 'gray',
      padding: isMobile ? 0.25 : 0.5,
      '& .MuiSvgIcon-root': {
        fontSize: isMobile ? '1.2rem' : '1.5rem',
      },
    }}
  >
    <MoreHorizIcon />
  </IconButton>
</Box>

                {/* <Typography 
    sx={{ 
      color: "gray",
      fontSize: isMobile ? '0.75rem' : '1rem', // Smaller font for mobile
      fontWeight: 500
    }}
  >
    + Collection / Looqup Name
  </Typography> */}
                {/* <Box sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: isMobile ? 0.5 : 1
                }}> */}
                  {/* <AccessTimeIcon sx={{ 
      color: "gray",
      width: isMobile ? 14 : 20, // Smaller icon for mobile
      height: isMobile ? 14 : 20
    }} />
    <Typography sx={{ 
      color: 'gray',
      fontSize: isMobile ? '0.7rem' : '0.875rem' // Smaller font for mobile
    }}>
      Now
    </Typography> */}
                


              {/* Chat Messages */}
             <StyledChatBox ref={chatContainerRef}>
        {chatHistory.map((message, index) => (
          <ChatMessage
            key={index}
            isUser={message.isUser}
            content={message.content}
          />
        ))}
        <div ref={chatEndRef} />
      </StyledChatBox>

              {/* Search Input */}
              <SearchBar
                query={query}
                setQuery={setQuery}
                handleSearch={handleSearch}
                loading={loading}
              />
            </Box>

            {/* Middle Sidebar */}
            {isMiddleSidebarVisible && (
            <MiddleSidebar 
            isVisible={isMiddleSidebarVisible}
            getLastQueryAndResponse={getLastQueryAndResponse}/>   
             )}
          </Box>

          {/* Right Section: Data Sources & Related Questions */}
          <Box sx={{
            width: isMobile ? '100%' : '30%',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}>
            {/* Data Sources Panel */}
            {/* <Box sx={{ 
              bgcolor: 'rgba(69, 18, 129, 0.3)',
              borderRadius: '8px',
              p: 2,
              flexGrow: 1,
              maxHeight: isMobile ? '150px' : 'none',
              overflowY: 'auto',
            }}>
              <Typography sx={{ 
                fontSize: '1rem',
                fontWeight: 500,
                mb: 1.5
              }}>
                Data Sources
              </Typography>
            </Box> */}

            {/* Related Questions Panel */}
            <Box sx={{
              bgcolor: 'rgba(69, 18, 129, 0.3)',
              borderRadius: '8px',
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: isMobile ? '150px' : 'auto',
              overflow: 'hidden',
              maxHeight: isMobile ? '150px' : 'none',
            }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center', // Align image and text in the same row
              mb: 1.5,
            }}>
              <img src={RelatedIcon} alt="Related" style={{ width: '24px', marginRight: '8px' }} />
              <Typography sx={{
                fontSize: '1rem',
                fontWeight: 500,
              }}>
                Related Questions
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex', flexDirection: 'column', gap: 1, overflowY: 'auto', flexGrow: 1,
            }}>
              {relatedQuestions.length > 0 ? (
                relatedQuestions.map((question, index) => (
                  <Box
                    key={index}
                    sx={{
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
                      padding: '8px 12px', 
                      borderRadius: '4px', 
                      transition: 'box-shadow 0.3s ease', 
                      '&:hover': {
                        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)', 
                      },
                      marginBottom: index === relatedQuestions.length - 1 ? '16px' : '0', // Adds margin to the last item
                    }}
                    ><QuestionItem
                    question={question}
                    onAskQuestion={onAskQuestion}
                  />
                  </Box>
                ))
              ) : (
                <Typography sx={{
                  color: '1e1e2e',
                  fontSize: isMobile ? '0.7rem' : '0.8rem',
                  fontStyle: 'italic'
                }}>
                  No related questions available
                </Typography>
              )}
            </Box>
            </Box>
            {/* Trending Questions Panel */}
            {/* <Box sx={{
              bgcolor: 'rgba(69, 18, 129, 0.3)',
              borderRadius: '8px',
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: isMobile ? '150px' : 'auto',
              maxHeight: isMobile ? '150px' : 'none',
              overflow: 'hidden',

            }}>
              <Box sx={{
    display: 'flex',
    alignItems: 'center', 
    mb: 1.5,
  }}>
 <TrendingUp sx={{ fontSize: '24px', color: 'white', mr: 1 }} />   
  <Typography sx={{
      fontSize: '1rem',
      fontWeight: 500,
    }}>
      Trending Questions
    </Typography>
  </Box>

              <Box sx={{
                display: 'flex', flexDirection: 'column', gap: 1, overflowY: 'auto', flexGrow: 1,
              }}>
                {trendingQuestions.map((question, index) => (
                  <QuestionItem
                    key={index}
                    question={question}
                    onAskQuestion={onAskQuestion}
                  />
                ))}
              </Box>
            </Box> */}
            {/* Things to Do Next Panel */}
            <Box sx={{
              bgcolor: 'rgba(69, 18, 129, 0.3)',
              borderRadius: '8px',
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: isMobile ? '150px' : 'auto',
              overflow: 'hidden', maxHeight: isMobile ? '150px' : 'none',
            }}>
             <Box sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 1.5,
              }}>
                <img src={ThingsToDoNext} alt="Next Steps" style={{ width: '34px', marginRight: '8px' }} />
                <Typography sx={{
                  fontSize: '1rem',
                  fontWeight: 500,
                }}>
                  Things to Do Next
                </Typography>
              </Box>
              <Box sx={{
                display: 'flex', flexDirection: 'column', gap: 1, overflowY: 'auto', flexGrow: 1
              }}>
                {thingsToDoNext.map((item, index) => (
                  <Box
                    key={index}
                    onClick={item.onClick}
                    sx={{
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      padding: '8px 12px', 
                      borderRadius: '4px', 
                      transition: 'box-shadow 0.3s ease',
                      '&:hover': {
                        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)', 
                      },
                      marginBottom: index === relatedQuestions.length - 1 ? '16px' : '0', // Adds margin to the last item
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'white',
                        fontSize: isMobile ? '0.7rem' : '0.8rem',
                        fontWeight: 500,
                      }}
                    >
                      {item.text}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default AnswerPage;