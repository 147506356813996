import React, { useState ,useEffect} from "react";
import { Paper, Typography, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box, useTheme,Popper, ClickAwayListener  } from "@mui/material";
import { TouchApp,Close  } from '@mui/icons-material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, Label, Legend } from "recharts";
import { RiExpandDiagonalLine, RiCloseLine } from "react-icons/ri";
import { API_URL } from "../../constants/AppConstants";
import { useMediaQuery } from '@mui/material';
import { DescriptionLoader } from "./DescriptionLoader";
import { ChartDescription } from './ChartDescription'
import logoSvg from '../../assets/logo.svg';
import { setMaxListeners } from "process";
import qIcon from '../../assets/Qicon.png';
const COLORS = ['#E2BBE9', '#9B86BD', '#7776B3', '#5A639C'];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{
        backgroundColor: "rgba(46, 46, 62, 0.8)",
        padding: "10px",
        borderRadius: "5px",
        color: "#FFFFFF",
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.3)",
        fontSize: "12px"
      }}>
        <p style={{ margin: "0", fontWeight: "bold" }}>{label}</p>
        {/* <p style={{ margin: "5px 0 0", color: payload[0].payload.color }}>{`Cost: ${payload[0].value}`}</p> */}
        {payload.map((entry, index) => (
          <p key={`item-${index}`} style={{ margin: "5px 0 0", color: entry.color }}>
            {`${entry.name}: ${entry.value.toFixed(2)}`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const ChartContent = ({ data, dataKey, width, height, onMouseEnter, onMouseLeave, onClick, legend, xAxisKey, yAxisKey }) => {
  const fnbData = JSON.parse(localStorage.getItem('fnb'));
  const RoleId = fnbData.role_id;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <ResponsiveContainer width={width} height={height}>
      <BarChart
        data={data}
        margin={{ top: 20, right: 20, left: 0, bottom: isMobile ? 60 : 20 }}
        onMouseLeave={onMouseLeave}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="#444" />
        <XAxis
  dataKey="name"
  tick={{
    fill: "#bbbbbb",
    fontSize: isMobile ? 10 : 10,
    angle: isMobile ? -45 :-25 ,
    textAnchor: 'end',
    dy: isMobile ? 10 : 0
  }}
          height={isMobile ? 60 : 30}
          interval={0}
          tickFormatter={(value) => value} >
          {RoleId=='1' &&
          <Label value={xAxisKey} offset={-20} position="insideBottom" fill="#bbbbbb" />}
        </XAxis>
        <YAxis
          tick={{ fill: "#bbbbbb", fontSize: 12 }}
          tickFormatter={(value) => `${value}`} >
          <Label value={yAxisKey} angle={-90} position="insideLeft" fill="#bbbbbb" />
        </YAxis>

        <Tooltip
          content={<CustomTooltip />}
          cursor={{ fill: "transparent" }}
        />
        <Bar
          dataKey={legend}
          onMouseEnter={onMouseEnter}
          onClick={onClick}
          radius={[10, 10, 0, 0]}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const BarChartComponent = ({ data, title, onRemove, MetricId, description, legend, displayname, chartType, xAxisKey, yAxisKey }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [descEnabled, setDescEnabled] = useState(false)
  const [drillDownData, setDrillDownData] = useState(null);
  const [isDrillDown, setIsDrillDown] = useState(false);
  
  const [summary, setSummary] = useState("");
  const [charts, setCharts] = useState([]);
  const initialDataKey = Object.keys(data[0]).find(key => key !== 'name');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [currentDataKey, setCurrentDataKey] = useState(Object.keys(data[0]).find(key => key !== 'name'));
  const [desc, setdesc] = useState("");
  const [isDescLoading, setIsDescLoading] = useState(false);
  const [isDrillDownSummaryLoading, setIsDrillDownSummaryLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isExtraSmall = useMediaQuery('(max-width:320px)');
  const [isDescriptionPopupOpen, setIsDescriptionPopupOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperOpen, setPopperOpen] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const fnbData = JSON.parse(localStorage.getItem('fnb'));
  useEffect(() => {
    const storedRoleId =fnbData.role_id;
    setRoleId(Number(storedRoleId));
  }, []);

  const fetchSummary = async (isDrillDownView = false) => {
    setIsDescLoading(true);
    try {
      const dataToSend = isDrillDownView ? drillDownData : formattedInitialData;
      const response = await fetch(`${API_URL}/summariserData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            MetricId,
            MetricDisplayName: title,
            MetricDescription: description,
            MetricDetails: {
              title,
              chart_type: chartType,
              legend,
              data: dataToSend,
          },
          isDrillDown: isDrillDownView
          }
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setdesc(result.summarised_data);
    } catch (error) {
      console.error('Error fetching summary:', error);
    }
    finally {
      setIsDescLoading(false);
    }
  };
  const toggleModal = () => {
    setIsOpen(!isOpen);
    // if (!isOpen) {
    //   setDrillDownData(null);
    //   setIsDrillDown(false);
    // }
    if (!isOpen) {
      // setIsDescLoading(true);
      // fetchSummary();
    } else {
      setIsDescLoading(false);
    }
  };

  const handleOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setDescEnabled(false);
  };

  const handleConfirmDelete = () => {
    if (onRemove) {
      onRemove(MetricId);
    }
    handleCloseDeleteDialog();
  };

  // const handleRemove = () => {
  //   if (onRemove) {
  //     onRemove(MetricId);
  //   }
  // };

  const formattedInitialData = data.map((item, index) => ({
    ...item,
    color: COLORS[index % COLORS.length]
  }));

  const handleMouseEnter = (_, index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

const handleBarClick = async (data) => {
    if (roleId === 2 || isDrillDown) return; // Prevent drill-down for role_id 2 or if already in drill-down view

    setIsDrillDownSummaryLoading(true);
    const user_id = fnbData.user_role_id;
    const fromDate = data.from_date;
    const toDate = data.to_date;

    try {
      const response = await fetch(`${API_URL}/LoadMetricsToCanvas`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_role_id: [user_id],
          role_id: roleId?.toString(),
          summary: "",
          metrics: [{
            "MetricId": MetricId,
            "MetricDisplayName": displayname
          }],
          fromDate,
          toDate,
          "drilldown": true
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      const metricDetails = result.metricDetails[0].MetricDetails;
      const drillDownDataKey = metricDetails.legend || metricDetails.Legend || Object.keys(metricDetails.data[0]).find(key => key !== 'name' && key !== 'from_date' && key !== 'to_date');
      setDrillDownData(metricDetails.data);
      setIsDrillDown(true);
      setCurrentDataKey(drillDownDataKey);
      // Remove automatic summary loading here
      setDescEnabled(false); 

      const summaryResponse = await fetch(`${API_URL}/summariserData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            MetricId,
            MetricDisplayName: title,
            MetricDescription: description,
            MetricDetails: {
              title,
              chart_type: chartType,
              legend: drillDownDataKey,
              data: metricDetails.data,
            }
          }
        })
      });

      if (!summaryResponse.ok) {
        throw new Error('Network response was not ok for summary');
      }

      const summaryResult = await summaryResponse.json();
      setSummary(summaryResult.summarised_data);

    } catch (error) {
      console.error('Error fetching drill-down data:', error);
      setSummary("Failed to load summary.");
    } finally {
      setIsDrillDownSummaryLoading(false);
    }
  };

  const handleBackToPrevious = () => {
    setDrillDownData(null);
    setIsDrillDown(false);
    setCurrentDataKey(Object.keys(data[0]).find(key => key !== 'name'));
    setDescEnabled(false); // Reset description state when going back
    setSummary(""); // Clear drill-down summary
  };
  // const updatedData = data.map(item => ({
  //   ...item,
  //   dateRange: `${item.from_date} - ${item.to_date}` // Create a new key for the combined date range
  // }));


  //   const currentModalData = isDrillDown ? drillDownData : formattedInitialData;
  // const currentModalDataKey = isDrillDown ? 'Cost' : initialDataKey;
  // const currentModalTitle = isDrillDown ? "Daily Breakdown" : title;
  const currentModalData = isDrillDown ? drillDownData : formattedInitialData;
  const currentModalTitle = isDrillDown ? "Daily Breakdown" : title;
  const xAxisKeyLabel = isDrillDown ? "" : xAxisKey;
  const yAxisKeyLabel = yAxisKey;
  console.log("YKey", yAxisKeyLabel)


  const handleQIconClick = () => {
    setDescEnabled(true);
    fetchSummary(isDrillDown);
  };

  // const xAxisKeyLabel = xAxisKey
  const handleQClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPopperOpen(true);
    setDescEnabled(true);
    fetchSummary(isDrillDown);
  };
  const handleClosePopper = (event) => {
    console.log("Close button clicked");
    // Prevent the event from bubbling up to the ClickAwayListener
    if (event) {
      event.stopPropagation();
    }
    setPopperOpen(false);
    setAnchorEl(null);
    setDescEnabled(false);
  };
  return (
    <>
      <Paper
        sx={{
          padding: isExtraSmall ? "10px" : "20px",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          color: "#FFFFFF",
          borderRadius: "15px",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
          width: isExtraSmall ? "280px" : isMobile ? "300px" : "350px",
          transition: "transform 0.3s ease",
          '&:hover': {
            transform: 'scale(1.05)',
            zIndex: 1,
          },
          position: 'relative',
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: "10px", fontWeight: "bold", display:'flex', justifyContent:'space-between', alignItems:'center', fontSize: isExtraSmall ? '0.8rem' : isMobile ? '0.9rem' : '1rem' }}>
          {title || "Bar Chart"}
          <div>
            <IconButton size="small" onClick={toggleModal} sx={{ color: '#fff', mr: 1 }}>
              <RiExpandDiagonalLine />
            </IconButton>
            <IconButton size="small" onClick={handleOpenDeleteDialog} sx={{ color: '#fff' }}>
              <RiCloseLine />
            </IconButton>
          </div>
        </Typography>
  
        <ChartContent
          data={formattedInitialData}
          dataKey={currentDataKey}
          width="100%"
          height={310}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          isExpanded={false}
          legend={legend}
          xAxisKey={xAxisKeyLabel}
          yAxisKey={yAxisKeyLabel}
        />
    <IconButton 
          onClick={handleQClick}
          sx={{
            position: 'absolute',
            right: '-15px',
            bottom: '-15px',
            backgroundColor: 'transparent',
            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
          }}
        >
          <img src={qIcon} alt="Q Icon" style={{ width: '35px', height: '35px' }} />
        </IconButton>
      </Paper>
      <Popper
        open={popperOpen}
        anchorEl={anchorEl}
        placement={isExtraSmall ? "top" : "left-start"}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: isExtraSmall ? [0, -10] : [-10, 0],
            },
          },
          {
            name: 'flip',
            enabled: true,
            options: {
              fallbackPlacements: ['bottom', 'right'],
            },
          },
        ]}
        style={{ zIndex: 1300 }}
      >
        <Box
          sx={{
            padding: isExtraSmall ? 1.5 : 2,
            backgroundColor: "#FFFFFF",
            color: "#333",
            width: isExtraSmall ? '250px' : '300px',
            maxWidth: '100%',
            maxHeight: isExtraSmall ? '50vh' : 400,
            overflow: 'auto',
            position: 'relative',
            borderRadius: '12px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
            zIndex: 1500,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Typography variant="h6" sx={{ 
              fontWeight: 'bold', 
              color: '#a084e8', 
              textAlign: 'justify', 
              margin: 0,
              fontSize: isExtraSmall ? '0.9rem' : '1rem'
            }}>
              Looqup Explains
            </Typography>
            <IconButton
              onClick={handleClosePopper}
              sx={{
                color: '#333',
                padding: '4px',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.05)' },
              }}
            >
              <Close fontSize={isExtraSmall ? "small" : "medium"} />
            </IconButton>
          </Box>
          {isDescLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '50%', my: 2 }}>
              <DescriptionLoader />
            </Box>
          ) : (
            <Box sx={{ 
              width: '100%',
              padding: 0,
              margin: 0,
              '& > *': {
                width: '100%',
                padding: 0,
                margin: 0
              }
            }}> 
             <ChartDescription desc={isDrillDown ? summary : desc} />
            </Box>
          )}
        </Box>
      </Popper>
    
      <Dialog
        open={isOpen}
        onClose={toggleModal}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: "#1a1a2e",
            color: "#FFFFFF",
          },
        }}
      >
        
        <DialogTitle sx={{
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          fontSize: { xs: '0.6rem', sm: '1.5rem' },
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {isDrillDown ? "Daily Breakdown" : (title || "Expanded Bar Chart")}
            {!isDrillDown && roleId !== 2 && (
              <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                <TouchApp sx={{ ml: 1, color: '#E2BBE9', fontSize: { xs: '0.8rem', sm: '1.2rem' } }} />
                <Typography
                  variant="body2"
                  sx={{
                    color: '#E2BBE9',
                    fontSize: { xs: '0.5rem', sm: '0.9rem' }
                  }}
                >
                  Click on a bar to see the drill-down data
                </Typography>
              </Box>
            )}
          </Box>
        </DialogTitle>
  
        <DialogContent sx={{ pt: 2 }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%'
          }}>
            <img 
              src={logoSvg} 
              alt="Logo" 
              style={{ width: '42px', height: '41px', cursor: 'pointer' }} 
              onClick={() => handleQIconClick(isDrillDown)}
            />
            
            <Box sx={{ width: '100%' }}>
              {(isDrillDown ? isDrillDownSummaryLoading : isDescLoading) && descEnabled ? (
                <Box sx={{ display: 'flex', justifyContent: 'left', width: '100%', my: 2 }}>
                  <DescriptionLoader />
                </Box>
              ) : descEnabled ? (
                <ChartDescription desc={isDrillDown ? summary : desc} />
              ) : null}
  
              {isDrillDown && roleId !== 2 && (
                <Button
                  onClick={handleBackToPrevious}
                  color="primary"
                  sx={{
                    color: '#fff',
                    mb: 2,
                    fontSize: { xs: '0.8rem', sm: '0.9rem' }
                  }}
                >
                  Back to Weekly View
                </Button>
              )}
            </Box>
          </Box>
  
          <ChartContent
            data={currentModalData}
            dataKey={currentDataKey}
            width="100%"
            height={400}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={roleId !== 2 ? handleBarClick : undefined}
            xAxisKey={xAxisKeyLabel}
            legend={legend}
            yAxisKey={yAxisKeyLabel}
          />
       
      
        </DialogContent>
  
        <Button
          onClick={toggleModal}
          color="primary"
          sx={{
            color: '#fff',
            m: 2,
            fontSize: { xs: '0.9rem', sm: '1rem' }
          }}
        >
          Close
        </Button>
      </Dialog>
  
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "#1a1a2e",
            color: "#FFFFFF",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
          {"Confirm Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ color: '#FFFFFF', fontSize: { xs: '0.9rem', sm: '1rem' } }}>
            Are you sure you want to delete this chart?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BarChartComponent;