import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, InputBase, Button, CircularProgress, Grid, MenuItem, Select, useMediaQuery, useTheme, Skeleton } from '@mui/material';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import Carousel from 'react-material-ui-carousel';
import ChevronIcon from '../assets/Chevron.png';
import AttachIcon from '../assets/Attach.png';
import SpeakIcon from '../assets/Speak.png';
import { useNavigate } from "react-router-dom";
import ChatIcon from '@mui/icons-material/Chat';
import LineChartComponent from '../components/LineChartComponent';
import BarChartComponent from '../components/charts/BarChartComponent.jsx';
import PieChartComponent from '../components/PieChartComponent';
import { toast, ToastContainer } from 'react-toastify';
import Sidebar from '../components/Sidebar';
import CustomizableSidebar from '../components/CustomizableSidebar';
import AnswerPage from './AnswerPage';
import 'react-toastify/dist/ReactToastify.css';
import { getDateRangeByUserRole } from '../components/utils/dateUtils.ts';
import StackedBarChartComponent from '../components/Stack.js';
import { API_URL } from '../constants/AppConstants';
import MetricPopup from '../components/MetricPopup.js';
import calendarIcon from '../assets/calendar-icon.png';
import logoSvg from '../assets/logo.svg';
import { ChatBubbleButton } from '../components/buttons/ChatBubbleButton.js'



const Header = ({ toggleCustomSidebar, isSidebarOpen, selectedMonth, setSelectedMonth, selectedWeek, setSelectedWeek }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const fnbData = JSON.parse(localStorage.getItem('fnb'));

  const [firstName, setFirstName] = useState("User");
  const [userRole, setUserRole] = useState(null);
  const [months, setMonths] = useState([]);
  const [weeks, setWeeks] = useState([]);

  const getWeekNumber = (date) => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const dayOfWeek = firstDayOfMonth.getDay();
    return Math.ceil((date.getDate() + dayOfWeek) / 7);
  };

  const formatWeek = (date) => {
    const month = date.toLocaleString('default', { month: 'short' });
    const weekNum = getWeekNumber(date);
    return `${month}-Week${weekNum}`;
  };

  useEffect(() => {
    // Get first name and role from local storage
    const storedFirstName = fnbData.firstName;
    const userRole = fnbData.userRole;
    const latestDataDate = fnbData.latestDataDate;

    if (storedFirstName) {
      setFirstName(storedFirstName);
    }
    if (userRole) {
      setUserRole(parseInt(userRole, 10));
    }

    if (latestDataDate) {
      const latestDate = new Date(latestDataDate);

      // Calculate months
      const last12Months = [];
      const monthsDate = new Date(latestDate);

      for (let i = 0; i <= 13; i++) {
        const month = monthsDate.toLocaleString('default', { month: 'short' });
        const year = monthsDate.getFullYear().toString();
        last12Months.push(`${month}-${year}`);
        monthsDate.setMonth(monthsDate.getMonth() - 1);
      }

      // Reverse and set months
      const sortedMonths = last12Months.reverse();

      setMonths(sortedMonths);

      // Set default month if not already set
      if (!selectedMonth) {
        setSelectedMonth(sortedMonths[sortedMonths.length - 1]);
      }

      const last5WeeksRev = [];
      console.log("This is the latest date: ", latestDate);
      let weeksDate = new Date(latestDate);

      // Define a helper function to get the start and end of the week
      const getWeekStartEnd = (date) => {
        const startOfWeek = new Date(date);
        startOfWeek.setDate(date.getDate() - date.getDay()); // Sunday as start of week
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6); // Saturday as end of week
        return { startOfWeek, endOfWeek };
      };

      // Helper to check if a formatted week is unique
      const isUniqueWeek = (formattedWeek) => !last5WeeksRev.includes(formattedWeek);

      // Loop until we have collected 5 unique weeks
      while (last5WeeksRev.length < 5) {
        const { startOfWeek, endOfWeek } = getWeekStartEnd(weeksDate);

        // Format the week with the correct month and week
        const formattedWeek = formatWeek(startOfWeek); // Adjust `formatWeek` to reflect month boundary

        // Push if unique
        if (isUniqueWeek(formattedWeek)) {
          last5WeeksRev.push(formattedWeek);
        }

        // Move `weeksDate` to the previous week's Sunday
        weeksDate.setDate(weeksDate.getDate() - 7);
      }

      // Reverse to ensure latest week is last
      const last5Weeks = last5WeeksRev.reverse();

      setWeeks(last5Weeks);

      // Set the latest week (first in the array) as default if userRole !== 1
      if (userRole !== 1) {
        setSelectedWeek(last5Weeks[last5Weeks.length - 1]);
      }
    }
  }, [setSelectedMonth, selectedMonth, setSelectedWeek]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleWeekChange = (event) => {
    setSelectedWeek(event.target.value);
  };

  return (
    <Box sx={{
      p: { xs: 2, sm: 4 },
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      alignItems: 'center',
      gap: { xs: 2, sm: 0 },
      position: 'relative',
    }}>
      <IconButton
        onClick={toggleCustomSidebar}
        sx={{
          color: 'white',
          position: { xs: 'absolute', sm: 'static' },
          left: { xs: 16, sm: 'auto' },
          top: { xs: 16, sm: 'auto' },
          zIndex: 1000,
        }}
      >
        {/* {isSidebarOpen ? <CloseIcon /> : <MenuIcon />} */}
        {!isSidebarOpen && <MenuIcon />}
      </IconButton>

      <Box sx={{
        flexGrow: 1,
        textAlign: { xs: 'center', sm: 'left' },
        ml: { xs: 0, sm: 8 }
      }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            fontSize: {
              xs: '1.5rem',
              sm: '2rem',
              md: '2.5rem',
              lg: '3rem'
            },
            background: 'linear-gradient(45deg, #e879f9, #8b5cf6)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {`${firstName}'s Looqup `}
        </Typography>
      </Box>

      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={2} mt={{ xs: 2, sm: 0 }}>
        {userRole === 1 ? (
          <Box>
            <Typography variant="body2" sx={{ color: 'white', marginBottom: 1 }}>
              {/* Select Month */}
            </Typography>
            <Select
              value={selectedMonth || ''}
              onChange={handleMonthChange}
              variant="outlined"
              sx={{
                backgroundColor: 'transparent',
                color: 'white',
                borderColor: 'white',
                width: { xs: '100%', sm: '150px' },
                height: '40px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white'
                },
                '& .MuiSvgIcon-root': {
                  color: 'white'
                },
                '& .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                  '&::before': {
                    content: '""',
                    backgroundImage: `url(${calendarIcon})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    width: '16px',
                    height: '16px',
                    marginRight: '8px',
                    display: 'inline-block'
                  }
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: '#2b155a',
                    color: 'white',
                    '& .MuiMenuItem-root': {
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      },
                    },
                  },
                },
              }}
            >
              {months.map((month, index) => (
                <MenuItem key={index} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </Box>
        ) : (
          <Box>
            <Typography variant="body2" sx={{ color: 'white', marginBottom: 1 }}>
              {/* Select Week */}
            </Typography>
            <Select
              value={selectedWeek || ''}
              onChange={handleWeekChange}
              variant="outlined"
              sx={{
                backgroundColor: 'transparent',
                color: 'white',
                borderColor: 'white',
                width: { xs: '100%', sm: '150px' },
                height: '40px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white'
                },
                '& .MuiSvgIcon-root': {
                  color: 'white'
                },
                '& .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                  '&::before': {
                    content: '""',
                    backgroundImage: `url(${calendarIcon})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    width: '16px',
                    height: '16px',
                    marginRight: '8px',
                    display: 'inline-block'
                  }
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: '#2b155a',
                    color: 'white',
                    '& .MuiMenuItem-root': {
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      },
                    },
                  },
                },
              }}
            >
              {weeks.map((week, index) => (
                <MenuItem key={index} value={week}>
                  {week}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      </Box>
    </Box>
  );
};


const ChartCarousel = ({ isSidebarOpen, chartsData, onRemoveChart, isLoadingCharts }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const maxCharts = 6;
  const [chartsPerPage, setChartsPerPage] = useState(3);

  useEffect(() => {
    const updateChartsPerPage = () => {
      if (isMobile) {
        setChartsPerPage(1);
      } else if (isTablet || isSidebarOpen) {
        setChartsPerPage(2);
      } else {
        setChartsPerPage(3);
      }
    };

    updateChartsPerPage();
    window.addEventListener('resize', updateChartsPerPage);

    return () => {
      window.removeEventListener('resize', updateChartsPerPage);
    };
  }, [isSidebarOpen, isMobile, isTablet]);

  const getChartWidth = () => {
    if (isMobile) return '100%';
    if (isTablet || (isSidebarOpen && chartsPerPage === 2)) {
      return 'calc(50% - 8px)';
    }
    if (chartsPerPage === 3) return 'calc(33.33% - 10.67px)';
    return '100%';
  };
  useEffect(() => {
    if (chartsData.length > maxCharts) {
      toast.error("You cannot add more than 6 charts.");
    }
  }, [chartsData]);

  const renderChart = (chartData) => {
    const { chart_type, data, title, Legend, x_axis, y_axis_label } = chartData.MetricDetails;

    const MetricId = chartData.MetricId;
    const desc = chartData.MetricDescription;
    const displayname = chartData.MetricDisplayName;
    if (!data || data.length === 0) {
      return (
        // <Box sx={{ 
        //   height: '300px', 
        //   display: 'flex', 
        //   alignItems: 'center', 
        //   justifyContent: 'center',
        //   border: '1px solid #ccc',
        //   borderRadius: '4px',
        //   padding: '16px',
        //   backgroundColor: '#f5f5f5'
        // }}>
        <Typography>
          No data available for {displayname}
        </Typography>
        // </Box>
      );
    }
    switch (chart_type) {
      case 'line_chart':
        return <LineChartComponent data={data} title={title} legend={Legend} MetricId={MetricId} onRemove={onRemoveChart} xAxisKey={x_axis} />;
      case 'pie_chart':
        return <PieChartComponent data={data} title={title} />;
      case 'bar_chart':
        const yAxisKey = y_axis_label;
        return <BarChartComponent data={data} title={title} legend={Legend} MetricId={MetricId} onRemove={onRemoveChart} description={desc} displayname={displayname} chartType={chart_type} xAxisKey={x_axis} yAxisKey={yAxisKey} />;

      case 'stacked_bar_chart':
        const xAxisKey = x_axis;
        const yAxisKeyLabel = y_axis_label;
        return <StackedBarChartComponent data={data} title={title} xAxisKey={xAxisKey} MetricId={MetricId} onRemove={onRemoveChart} description={desc} displayname={displayname} chartType={chart_type} YAxisKey={yAxisKeyLabel} />;
      default:
        console.warn(`Unsupported chart type: ${chart_type}`);
        return null;
    }
  };
  const renderSkeletons = () => {
    return Array(chartsPerPage).fill().map((_, index) => (
      <Box key={index} sx={{
        borderRadius: 5,
        width: getChartWidth(),
        height: '390px',
        m: 0.5
      }}>
        <Skeleton variant="rounded" width="100%" height="100%" animation="wave" sx={{
          bgcolor: '#dabfff',
          opacity: 0.1,
          animationDuration: '1.5s',
          borderRadius: 'inherit'
        }} />
      </Box>
    ));
  };

  return (
    <>
      <Carousel
        animation="slide"
        navButtonsAlwaysVisible
        autoPlay={false}
        navButtonsProps={{
          style: {
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            borderRadius: 0
          },
        }}
        navButtonsWrapperProps={{
          style: {
            zIndex: 8,
            height: '50%',
            justifyContent: 'center',
            marginTop: '100px'
          },
        }}
        sx={{
          width: '100%',
          height: 'auto',
          minHeight: { xs: '300px', sm: '400px', md: '500px' },
          position: 'relative',
        }}
      >
        {isLoadingCharts ? (
          <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100%'
          }}
        >
          <Typography 
            
            sx={{ 
              color: 'white',
              textAlign: 'center',
              fontSize: {
                xs: '0.8rem', 
                sm: '1rem'        
              }
            }}
          >
            Generating your insights...
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', gap: 1 }}>
            {renderSkeletons()}
            </Box>
          </Box>
        ) : chartsData.length === 0 ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            No chart data available
          </Box>
        ) : (
          Array(Math.ceil(Math.min(chartsData.length, maxCharts) / chartsPerPage))
            .fill()
            .map((_, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  gap: 1,
                  p: 1,
                  height: '100%',
                }}
              >
                {chartsData
                  .slice(index * chartsPerPage, index * chartsPerPage + chartsPerPage)
                  .map((chartData, i) => (
                    <Box
                      key={i}
                      sx={{
                        width: getChartWidth(),
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      {renderChart(chartData)}
                    </Box>
                  ))}
              </Box>
            ))
        )}
      </Carousel>
    </>
  );
};

const SearchBar = ({ query, setQuery, handleSearch, loading }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%)',
        borderRadius: 3,
        p: 2,
        mt: 4,
        mx: { xs: 2, sm: 4 },
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        maxHeight: '100px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <InputBase
          sx={{ color: 'white', fontSize: '1rem', mb: 1 }}
          placeholder="Ask about the sale of Mutton Biryani or total sales..."
          inputProps={{ 'aria-label': 'search' }}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={handleKeyPress}
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            <Button
              startIcon={
                <Box
                  component="img"
                  src={AttachIcon}
                  alt="Attach"
                  sx={{ width: 24, height: 24 }}
                />
              }
              sx={{ color: 'white', textTransform: 'none', fontSize: { xs: '0.8rem', sm: '1rem' } }}
            >
              Attach
            </Button>
            <Button
              startIcon={
                <Box
                  component="img"
                  src={SpeakIcon}
                  alt="Speak"
                  sx={{ width: 24, height: 24 }}
                />
              }
              sx={{ color: 'white', textTransform: 'none', fontSize: { xs: '0.8rem', sm: '1rem' } }}
            >
              Speak
            </Button>
          </Box>
          <IconButton sx={{ color: 'white', p: 1 }} onClick={handleSearch} disabled={loading}>
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <Box
                component="img"
                src={ChevronIcon}
                alt="Search"
                sx={{ width: 24, height: 24 }}
              />
            )}
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

const TodoItem = ({ title, isHighRisk, onClick }) => (
  <Box
    onClick={onClick}
    sx={{
      display: 'flex',
      alignItems: 'center',
      background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%)',
      borderRadius: 2,
      p: 1.5,
      mb: 2,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
      minHeight: '35px', // Ensure a minimum height for consistency
      overflow: 'hidden', // Prevent overflow
    }}
  >
    {isHighRisk && (
      <Box
        sx={{
          width: 8,
          height: 8,
          borderRadius: '50%',
          bgcolor: '#ef4444',
          mr: 2,
        }}
      />
    )}
    <Typography
      flex={1}
      sx={{
        fontSize: { xs: '0.8rem', sm: '0.9rem' },
        wordWrap: 'break-word', // Wrap text inside the box
        lineHeight: 1.2, // Adjust line height for text wrapping
        overflow: 'hidden',
        whiteSpace: 'normal', // Allow text to wrap naturally
        height: 'auto', // Auto height to adjust for wrapped lines
      }}
    >
      {title}
    </Typography>    {/* <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: { xs: '0.7rem', sm: '0.8rem' } }}>
      Learn More →
    </Typography> */}
  </Box>
);

const TodoList = ({ handleProductMix }) => {
  const navigate = useNavigate();
  const fnbData = JSON.parse(localStorage.getItem('fnb'));

  const handleNavigate = () => {
    navigate('/reports')
  }
  const handleWaClick = () => {
    const phoneNumber = '+16593336699';
    const message = "Hello, can we have a repeat on the order for last week? "
    const encodedMessage = encodeURIComponent(message);
    const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(url, '_blank'); // Open in a new tab
  };

  return (
    <Box sx={{ p: { xs: 2, sm: 4 } }}>
      <Typography variant="subtitle1" sx={{ color: '#9ca3af', mb: 2, mt: 3, fontSize: { xs: '1rem', sm: '1rem' } }}>
        Things to Do Next
      </Typography>
      {fnbData.user_id  === '1' ? (
        <Box sx={{ maxWidth: '600px' }}>

          {/* <TodoItem title="Fraud Risk – Review 1 Order" isHighRisk /> */}
          {/* <TodoItem title="Generate new sales broadcast" /> */}
          <TodoItem title="Generate the Financial Report for this month" onClick={handleNavigate} />
          <TodoItem title="Talk to your top vendor on WhatsApp" onClick={handleWaClick} />
          <TodoItem title="View Product Mix for next 12 months" onClick={handleProductMix({
            type: 'pop_up',
            MetricId: '15',
            MetricDisplayName: 'Cross Sell',
          })} />
        </Box>
      ) :
        (
          <Box sx={{ maxWidth: '600px' }}>

            {/* 
            <TodoItem title="Fraud Risk – Review 1 Order" isHighRisk /> */}
            {/* <TodoItem title="Generate new sales broadcast" /> */}
            {/* <TodoItem title="Generate the inventory order form" /> */}
            {/* <TodoItem title="Talk to your vendor on WhatsApp" onClick={handleWaClick} /> */}
            <TodoItem title="Generate the Financial Report for this month" onClick={handleNavigate} />
            <TodoItem title="Talk to your top vendor on WhatsApp" onClick={handleWaClick} />
            <TodoItem title="View Product Mix for next 12 months" />
            {/* <TodoItem title="View Product Mix for next 12 months" onClick={handleProductMix({
              type: 'pop_up',
              MetricId: 15,
              MetricDisplayName: 'Cross Sell',
          })} /> */}
          </Box>
        )
      }
    </Box>
  );
}

const TrendingQuestions = ({ onQuestionClick }) => (
  <Box sx={{ p: { xs: 2, sm: 4 } }}>
    <Typography variant="subtitle1" sx={{ color: '#9ca3af', mb: 2, mt: 3, fontSize: { xs: '1rem', sm: '1rem' } }}>
      Trending Questions
    </Typography>
    <Box sx={{ maxWidth: '600px' }}>
      <TodoItem title="What day of week is the sales of Rice Options seen as spiking?" onClick={() => onQuestionClick("What day of week is the sales of Rice Options seen as spiking?")} />
      <TodoItem title="Show the top 5 fast selling items and the revenue generated in a table format." onClick={() => onQuestionClick("Show the top 5 fast selling items and the revenue generated in a table format.")} />
      <TodoItem title="What are the spending patterns seen in customers who buy in Rice Option category?" onClick={() => onQuestionClick("What are the spending patterns seen in customers who buy in Rice Option category?")} />
    </Box>
  </Box>
);

const TalkToData = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [query, setQuery] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchThread, setSearchThread] = useState([]);
  const [customSidebarOpen, setCustomSidebarOpen] = useState(false);
  const [showTalkToData, setShowTalkToData] = useState(false);
  const [chartsData, setChartsData] = useState([]);
  const navigate = useNavigate();
  const [isLoadingCharts, setIsLoadingCharts] = useState(true);
  const [summary, setSummary] = useState("");
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState('Week 1');
  const fnbData = JSON.parse(localStorage.getItem('fnb'));


  const maxCharts = 6;
  const handleRemoveChart = (MetricId) => {
    setChartsData(prevCharts => prevCharts.filter(chart => chart.MetricId !== MetricId));
  };

  // **Modified useEffect to include selectedMonth as a dependency**
  useEffect(() => {
    const fetchChartsData = async () => {
      setIsLoadingCharts(true);
      try {
        const insight_id = fnbData.insightId;
        const user_id = fnbData.user_role_id ;
        const userRole = fnbData.userRole;
        const userRoleNumber = parseInt(userRole, 10);

        const { fromDate, toDate } = getDateRangeByUserRole(
          [userRoleNumber],
          selectedMonth,  // Already in format "MMM-YY"
          selectedWeek   // Already in format "MMM-WeekN"
        );

        if (fromDate && toDate) {
          const response = await fetch(`${API_URL}/getTopMetricsForUser`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              insight_id: insight_id,
              user_role_id: user_id,
              fromDate,
              toDate,
            }),
          });

          const data = await response.json();
          setChartsData(data.top_metrics.slice(0, maxCharts));
          localStorage.setItem('originalChartsData', JSON.stringify(data));
        }
      } catch (error) {
        console.error('Error fetching chart data:', error);
      } finally {
        setIsLoadingCharts(false);
      }
    };

    fetchChartsData();
  }, [selectedMonth, selectedWeek]);

  const handleQuestionClick = (question) => {
    setQuery(question);
    setShowTalkToData(true);
    // Trigger the search immediately
    // handleSearch(question);
  };



  const handleSearch = async () => {
    setLoading(true);
    setCurrentAnswer('');
    try {
      const response = await fetch(`${API_URL}/lang_query`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.response) {
        navigate('/answer', { state: { question: query, answer: data.response } });
      } else {
        console.error('No answer received from the API.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAskQuestion = (newQuestion) => {
    setQuery(newQuestion);
    handleSearch();
  };

  const toggleCustomSidebar = () => {
    setCustomSidebarOpen(!customSidebarOpen);
  };

  const toggleTalkToData = () => {
    setShowTalkToData(!showTalkToData);
  };

  const handleAddChart = (selectedMetric) => {
    if (selectedMetric.type === 'pop_up') {
      setSelectedMetric(selectedMetric);
      setShowPopup(true);
    } else {
      if (chartsData.length >= 6) {
        toast.error("Maximum chart limit reached (Delete charts to add More)");
        return;
      }
      addMetricToCarousel(selectedMetric);
    }
  }

  const addMetricToCarousel = async (selectedMetric) => {
    try {
      const user_role_id =fnbData.user_role_id
      const role_id = fnbData.role_id 
      const userRole =fnbData.userRole
      const userRoleNumber = parseInt(userRole, 10);
      // console.log("userRoleNumber",userRoleNumber)
      const { fromDate, toDate } = getDateRangeByUserRole([userRoleNumber], selectedMonth);


      const metricInputDetails = {
        MetricId: selectedMetric.MetricId,
        MetricDisplayName: selectedMetric.MetricDisplayName
      }

      const payload = {
        user_role_id: [user_role_id],
        role_id: role_id,
        summary: "Your average transaction value per order has risen by 8%",
        metrics: [metricInputDetails],
        fromDate,
        toDate,
        "drilldown": false
      };

      const response = await fetch(`${API_URL}/LoadMetricsToCanvas`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      });

      const responseData = await response.json();
      const metricData = responseData.metricDetails[0];

      setChartsData((prevChartsData) => {
        if (prevChartsData.length >= 6) {
          return prevChartsData;
        }
        return [metricData, ...prevChartsData];
      });

      setTimeout(() => {
        if (chartsData.length >= 6) {
          toast.error("Maximum chart limit reached (6 charts)!", {
            toastId: 'max-chart-error'
          });
        } else {
          toast.success("New chart added successfully!", {
            toastId: 'chart-added-success',
            autoClose: 3000
          });
        }
      }, 0);

    } catch (err) {
      console.error('Failed to load metric data:', err);
      toast.error("Failed to load metric data", {
        toastId: 'metric-load-error'
      });
    }
  };


  return (
    <Box sx={{
      display: 'flex',
      minHeight: '100vh',
      flexDirection: { xs: 'column', sm: 'row' },
    }}>
      <ToastContainer />
      {!isMobile && <Sidebar />}
      {customSidebarOpen && <CustomizableSidebar onAddChart={handleAddChart} onClose={toggleCustomSidebar} />}
      {currentAnswer ? (
        <AnswerPage
          question={currentQuestion}
          answer={currentAnswer}
          onAskQuestion={handleAskQuestion}
          searchThread={searchThread}
        />
      ) : (
        <Box sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: '#1e1e2e',
          background: 'linear-gradient(118.12deg, #131B34 0%, #131B34 41.39%, #5B0CA2 94.83%, #9900FF 101.97%)',
          overflowY: 'auto',
        }}>
          <MetricPopup
            open={showPopup}
            onClose={() => setShowPopup(false)}
            MetricDisplayName={selectedMetric ? selectedMetric.MetricDisplayName : ''}
            MetricId={selectedMetric ? selectedMetric.MetricId : null}
          />

          <Header
            toggleCustomSidebar={toggleCustomSidebar}
            isSidebarOpen={customSidebarOpen}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            selectedWeek={selectedWeek}
            setSelectedWeek={setSelectedWeek}
          />

          <ChartCarousel
            isSidebarOpen={customSidebarOpen}
            chartsData={chartsData}
            onRemoveChart={handleRemoveChart}
            selectedMetric={selectedMetric}
            isLoadingCharts={isLoadingCharts}
          />
          <Box
            sx={{
              width: '100%',
              maxWidth: '1250px',
              margin: '0 auto',
              mt: -8,
              px: { xs: 2, sm: 4 },
            }}
          >
            {showTalkToData && (
              <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>

                <Grid item xs={12}>
                  <Box sx={{ maxWidth: '1250px', margin: '0 auto', mt: 4 }}>
                    <SearchBar
                      query={query}
                      setQuery={setQuery}
                      handleSearch={handleSearch}
                      loading={loading}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
            <Box
              sx={{
                mt: 4,
                px: { xs: 2, sm: 4 },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <Button
                onClick={toggleTalkToData}
                variant="contained"
                sx={{
                  color: 'white',
                  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%)',
                  '&:hover': { backgroundColor: '#2c387e' },
                  fontSize: { xs: '0.8rem', sm: '1rem' },
                  // mb: 2,
                  mt: 4,
                  borderRadius: '20px', // Rounded corners for the bubble effect
                  position: 'relative', // Needed for positioning the tail
                  padding: '10px 20px', // Adjust padding as needed
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '-10px', // Adjust this value for tail height
                    left: '15px', // Adjust this value for tail position
                    width: '0',
                    height: '0',
                    borderLeft: '10px solid transparent',
                    borderRight: '10px solid transparent',
                    borderTop: '10px solid transparent', // Same color as the button
                    borderTopColor: 'linear-gradient(135deg, #524b71 20%, #726a8d 80%)', // Match gradient color
                  },
                }}
                startIcon={<img src={logoSvg} alt="Logo" style={{ width: '42px', height: '41px' }} />}
              >
                Talk to Your Data
              </Button>
            </Box>
            {/* <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    maxWidth: '600px',
                    margin: '0 auto',
                    wordWrap: 'break-word',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <TrendingQuestions onQuestionClick={handleQuestionClick} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ maxWidth: '600px', margin: '0 auto', }}>
                  <TodoList />
                </Box>
              </Grid>
            </Grid> */}

            <Grid container spacing={2} justifyContent="center" alignItems="stretch"> {/* Align items to stretch */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    maxWidth: '600px',
                    height: '100%', // Ensure it takes full height
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',

                  }}
                >
                  <TrendingQuestions onQuestionClick={handleQuestionClick} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ maxWidth: '600px', height: '100%' }}> {/* Ensure it takes full height */}
                  <TodoList handleProductMix={handleAddChart} />
                </Box>
              </Grid>
            </Grid>



          </Box>
        </Box>
      )}
    </Box>
  );
};


export default TalkToData;