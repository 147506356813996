import React, { useState, useEffect } from 'react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { Dialog, DialogTitle, DialogContent, DialogActions, Paper, Typography, IconButton, CircularProgress, Button, MenuItem, FormControl, Select, InputLabel, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Carousel from 'react-material-ui-carousel';
import { RiCloseLine } from "react-icons/ri";
import { API_URL } from '../constants/AppConstants';
import WaterfallChart from './WaterfallChart';
import LineChartComponent from './LineChartComponent';
import { ChartDescription } from './charts/ChartDescription';
import { DescriptionLoader } from './charts/DescriptionLoader';
import logoSvg from '../assets/logo.svg';
const COLORS = ['#E2BBE9', '#9B86BD', '#7776B3', '#5A639C'];

const CustomTooltip = ({ active, payload, label, unit }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{
        backgroundColor: "rgba(46, 46, 62, 0.8)",
        padding: "10px",
        borderRadius: "5px",
        color: "#FFFFFF",
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.3)"
      }}>
        <p style={{ margin: "0", fontWeight: "bold" }}>{label}</p>
        {payload.map((entry, index) => (
          <p key={index} style={{ margin: "5px 0 0", color: entry.color }}>
            {`${entry.name}: ${entry.value}`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const MetricPopup = ({ open, onClose, MetricDisplayName, MetricId }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [lineChartData, setLineChartData] = useState([]);
  const [waterfallData, setWaterfallData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartTitle, setChartTitle] = useState('');
  const [selectedItem, setSelectedItem] = useState('Upma');

  const [demandLineChartData, setDemandLineChartData] = useState([]);
  const [demandLabel, setDemandLabel] = useState('');

  const [revenueLineChartData, setRevenueLineChartData] = useState([]);
  const [revenueLabel, setRevenueLabel] = useState('');
  const [crossSellBarChartData, setCrossSellBarChartData] = useState([]);
  const [upSellBarChartData, setUpSellBarChartData] = useState([]);

  const [demandForecastSummary, setDemandForecastSummary] = useState('');
  const [revenueForecastSummary, setRevenueForecastSummary] = useState('');
  const [upsellCrossSellSummary, setUpsellCrossSellSummary] = useState('');
  const [lineChartSummary, setLineChartSummary] = useState('');
  const [waterfallChartSummary, setWaterfallChartSummary] = useState('');
  const [descEnabled, setDescEnabled] = useState(false);

  const [isDescLoading, setIsDescLoading] = useState(false);
  const [activeChartIndex, setActiveChartIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const fnbData = JSON.parse(localStorage.getItem('fnb'));
  const [barChartLabels, setBarChartLabels] = useState({ 
    xAxis: '', 
    yAxis: '' 
  });
  const [availableItems, setAvailableItems] = useState([]);
  const handleQIconClick = () => {
    setDescEnabled(true);
    let chartType, chartData;
    
    if (MetricId === 12) {
      chartType = 'Demand Forecast Line Chart';
      chartData = { demandforecasting: { [selectedItem]: demandLineChartData } };
    } else if (MetricId === 13) {
      chartType = 'Revenue Forecast chart';
      chartData = { revenue_data: { data: revenueLineChartData } };
    } else if (selectedCategory) {
      chartType = MetricId === 14 ? 'Upsell Line Chart' : 'Cross-sell Line Chart';
      chartData = { chart_data: { [selectedCategory]: lineChartData } };
    } else {
      chartType = MetricId === 14 ? 'Upsell Bar Chart' : 'Cross-sell Bar Chart';
      chartData = { chart_data: MetricId === 14 ? upSellBarChartData : crossSellBarChartData };
    }

    fetchAdvancedMetricsDescription(chartType, chartData, selectedCategory || selectedItem);
  };
  const fetchAdvancedMetricsDescription = async (chartType, chartData, selectedItem = null) => {
    try {
      const payload = {
        chart_type: chartType,
        item: selectedItem,
        data: chartData
      };

      // const response = await fetch(`${API_URL}/advanced_metrics_description`, {
      const response = await fetch(`${API_URL}/summariserData`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      
      switch (chartType) {
        case 'Demand Forecast Line Chart':
          setDemandForecastSummary(data.summarised_data || '');
          break;
        case 'Revenue Forecast chart':
          setRevenueForecastSummary(data.summarised_data || '');
          break;
        case 'Upsell Bar Chart':
        case 'Cross-sell Bar Chart':
          setUpsellCrossSellSummary(data.summarised_data || '');
          break;
        case 'Upsell Line Chart':
        case 'Cross-sell Line Chart':
          setLineChartSummary(data.summarised_data || '');
          break;
        case 'Upsell Waterfall Chart':
        case 'Cross-sell Waterfall Chart':
          setWaterfallChartSummary(data.summarised_data || '');
          break;
        default:
          console.warn('Unknown chart type:', chartType);
      }
    } catch (error) {
      console.error('Error fetching advanced metrics description:', error);
    }
  };

  const parseWaterfallData = (responseData) => {
    const chartData = responseData?.chart_data?.series[0]?.data || [];
    const categories = chartData.map(item => item.name);
    const data = chartData.map(item => ({
      name: item.name,
      y: typeof item.y === 'number' ? item.y : 0,
      isSum: item.isSum || false,
    }));
    const chartTitle = responseData?.chart_data?.chartTitle || '';
    return { categories, data, chartTitle };
  };

  const fetchForecastData = async (item) => {
    setLoading(true);
    try {
      let endpoint = '/demandforecasting';
      const response = await fetch(`${API_URL}${endpoint}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "items": [item],
        }),
      });
      
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      const forecastData = data.quantity_data?.[item] || [];
      setDemandLineChartData(forecastData);
      setDemandLabel(data.quantity_data.metricLabel);

      // await fetchAdvancedMetricsDescription('Demand Forecast Line Chart', { demandforecasting: { [item]: forecastData } }, item);
    } catch (error) {
      console.error('Error fetching forecast data:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderDemandForecastChart = () => (
    <div style={{ width: '100%', height: 400 }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChartComponent 
        data={demandLineChartData}
                showDashedLine={true}
                title="Demand: Actual vs Forecast"
                label_yaxis={demandLabel}
                isRevenue={false} 
                >
          {/* <CartesianGrid strokeDasharray="3 3" stroke="#444" />
          <XAxis 
            dataKey="dates" 
            tick={{ fill: "#bbbbbb", fontSize: 12 }}
            tickFormatter={(value) => new Date(value).toLocaleDateString('en-US', { month: 'short', year: '2-digit' })}>
          <Label value="Month" offset={-10} position="insideBottomLeft" style={{ fill: '#bbbbbb' }} />
          </XAxis>
          <YAxis 
            tick={{ fill: "#bbbbbb", fontSize: 12 }}
            domain={['auto', 'auto']}>
              <Label value="Quantity" offset={2} position="insideLeft" angle={-90} style={{ textAnchor: 'middle', fill: '#bbbbbb' }} />
            </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Legend wrapperStyle={{ color: "#FFFFFF" }} />
          <Line type="monotone" dataKey="predictions" name="Predictions" stroke={COLORS[0]} strokeWidth={2} dot={{ fill: COLORS[0], stroke: COLORS[0], strokeWidth: 2 }} /> */}
        </LineChartComponent>
      </ResponsiveContainer>
    </div>
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let chartType = '';
        let chartData = {};
        if (MetricId === 12) {
          chartType = 'Demand Forecast Line Chart';
          await fetchForecastData(selectedItem);
        } else if (MetricId === 13) {
          chartType = 'Revenue Forecast chart';
          let endpoint = '/revenue_forecast';
          const response = await fetch(`${API_URL}${endpoint}`);
          if (!response.ok) throw new Error('Network response was not ok');
          const data = await response.json();

          console.log('Revenue forecast API response:', data);
    
          if (data.revenue_data ) {
            setRevenueLineChartData(data.revenue_data.data );
            setRevenueLabel(data.revenue_data.metricLabel);
            console.log('Revenue Line Chart Data set:', data.revenue_data );
            chartData = data.revenue_data;

          } else {
            setRevenueLineChartData([]);
            chartData = { revenue_data: { data: [] } };
          }
          // await fetchAdvancedMetricsDescription(chartType, chartData);
        } else {
          chartType = MetricId === 14 ? 'Upsell Bar Chart' : 'Cross-sell Bar Chart';
          let endpoint = MetricId === 14 ? '/upsellBarChart' : '/crossell_bar_graph';
          const response = await fetch(`${API_URL}${endpoint}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
          });
          if (!response.ok) throw new Error('Network response was not ok');
          const data = await response.json();
          if (MetricId === 14) {
            setUpSellBarChartData(data.chart_data || []);
          } else {
            setCrossSellBarChartData(data.chart_data || []);
          }
          setBarChartLabels({
            xAxis: data.XaxisLabel || 'Categories',
            yAxis: data.YaxisLabel || 'Values'
          });
         
          chartData = { chart_data: data.chart_data || [] };
          // await fetchAdvancedMetricsDescription(chartType, chartData);
        }
      
      } catch (error) {
        console.error('Error fetching chart data:', error);
      } finally {
        setLoading(false);
      }
    };
    
    if (open) fetchData();

    return () => {
      setLineChartSummary('');
      setDemandForecastSummary('');
      setRevenueForecastSummary('');
      setUpsellCrossSellSummary('');
      setDescEnabled(false); // Reset description state
    };
  }, [open, MetricId, selectedItem]);

  const renderLineChartForMetric13 = () => {
    return (
      <LineChartComponent
        data={revenueLineChartData}
        title="Revenue: Actual vs Forecast"
        showDashedLine={true}
        label_yaxis = {revenueLabel}
        isRevenue={true} 

      />
    );
  };
  
  const handleBarClick = async (data) => {
    setSelectedCategory(data.category);
    setDescEnabled(false); // Reset description state wh
    setLineChartData([]);
    const lineChartType = MetricId === 14 ? 'Upsell Line Chart' : 'Cross-sell Line Chart';
    const waterfallChartType = MetricId === 14 ? 'Upsell Waterfall Chart' : 'Cross-sell Waterfall Chart';

    try {
      // Fetch line chart data
      const lineEndpoint = MetricId === 14 ? '/upsellLineChart' : '/crossell_line_graph';
      const lineResponse = await fetch(`${API_URL}${lineEndpoint}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ category: data.category }),
      });

      if (!lineResponse.ok) throw new Error('Network response was not ok for line chart');
      const lineData = await lineResponse.json();
      setLineChartData(lineData.chart_data[data.category] || []);
      
      // Fetch line chart description
      // await fetchAdvancedMetricsDescription(lineChartType, { chart_data: lineData.chart_data }, data.category);

      // Fetch waterfall chart data
      // const waterfallEndpoint = MetricId === 14 ? '/upsellWaterfallChart' : '/crossell_waterfall_graph';
      // const waterfallResponse = await fetch(`${API_URL}${waterfallEndpoint}`, {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ category: data.category }),
      // });

      // if (!waterfallResponse.ok) throw new Error('Network response was not ok for waterfall chart');
      // const waterfallData = await waterfallResponse.json();
      // const { categories, data: waterfallChartData, chartTitle } = parseWaterfallData(waterfallData);
      // setWaterfallData(waterfallChartData);
      setCategories(categories);
      setChartTitle(chartTitle);

      // Fetch waterfall chart description
      // await fetchAdvancedMetricsDescription(waterfallChartType, waterfallData, data.category);

    } catch (error) {
      console.error('Error fetching category data:', error);
    }
  };

   
    
  const renderChartSummary = () => {
    if (!descEnabled) return null;
    
    if (isDescLoading) {
      return <DescriptionLoader />;
    }
    let summary = '';
    if (MetricId === 12) {
      summary = demandForecastSummary;
    } else if (MetricId === 13) {
      summary = revenueForecastSummary;
    } else if (selectedCategory) {
      summary = activeChartIndex === 0 ? lineChartSummary : waterfallChartSummary;
    } else {
      summary = upsellCrossSellSummary;
    }

    return (
      <Box sx={{ mb: 4 }}>
        <ChartDescription desc={summary}/>
      </Box>
      
      // <Typography variant="body2" sx={{ mb: 2, color: "#FFFFFF", fontStyle: "italic" }}>
      //   {summary}
      // </Typography>
    );
  };

  const renderLineChart = () => (
    <div style={{ width: '100%', height: 300 }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold", color: "#FFFFFF" }}>{selectedCategory} - Line Chart</Typography>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart 
          data={lineChartData}
          margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#444" />
          <XAxis dataKey="month" tick={{ fill: "#bbbbbb", fontSize: 12 }}
                label={{
                    value: "Month-Year", 
                    position: "insideBottom", 
                    fontSize: 14,
                    fontWeight: "bold",
                    dy: 20 // Adjust label position
                  }} 
          />
          <YAxis 
            tick={{ fill: "#bbbbbb", fontSize: 12 }} 
            tickFormatter={(value) => `${value}%`}
            label={{
              value: "Percentage", 
              angle: -90, 
              position: "insideLeft", 
              fill: "#bbbbbb", 
              fontSize: 14, 
              fontWeight: "bold",
              dx: -7 // Adjust Y-axis label position
            }} 
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend wrapperStyle={{ color: "#FFFFFF", paddingTop: 30 }} />
          <Line 
            type="monotone" 
            dataKey="percentage" 
            stroke={COLORS[0]} 
            strokeWidth={2} 
            dot={{ fill: COLORS[0], stroke: COLORS[0], strokeWidth: 2 }} 
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );

  const renderWaterfallChart = () => (
    <div style={{ width: '100%', height: 300 }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold", color: "#FFFFFF" }}>{selectedCategory} - Waterfall Chart</Typography>
      <WaterfallChart
        data={waterfallData}
        categories={categories}
        chartTitle={chartTitle}
      />
    </div>
  );

  const handleItemChange = (event) => {
    setSelectedItem(event.target.value);
    fetchForecastData(event.target.value);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const userRoleId = fnbData.user_role_id;
  
        let endpoint = '/forecast_items';
        const response = await fetch(`${API_URL}${endpoint}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            "user_role_id": [userRoleId],
          }),
        });
  
        if (!response.ok) throw new Error('Failed to fetch items');
        const data = await response.json();
        setAvailableItems(data.items);
  
        // Set initial selected item to first product if available
        if (data.items.length > 0) {
          setSelectedItem(data.items[0]);
          fetchForecastData(data.items[0]);
        }
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };
  
    if (MetricId === 12 && open) {
      fetchItems();
    }
  }, [MetricId, open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: { backgroundColor: "#1a1a2e", color: "#FFFFFF", width: '100%', maxWidth: '1000px' },
      }}
    >
      <DialogTitle sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {MetricDisplayName}
            </Typography>
            <IconButton size="small" onClick={() => {
              setSelectedCategory('');
              setDescEnabled(false);
              onClose();
            }} sx={{ color: '#fff' }}>
              <RiCloseLine />
            </IconButton>
          </Box>
          {(MetricId === 14 || MetricId === 15) && selectedCategory && (
            <Button
              onClick={() => {
                setSelectedCategory(null);
                setDescEnabled(false);
              }}
              color="primary"
              startIcon={<ArrowBackIcon />}
              sx={{ 
                color: '#fff', 
                alignSelf: 'flex-start', 
                mt: 1, 
                p: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                },
              }}
            >
              Back to Categories
            </Button>
          )}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: 2, maxHeight: '80vh', overflowY: 'auto', overflowX: 'hidden' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
          <img 
            src={logoSvg} 
            alt="Show Description" 
            style={{ width: '42px', height: '41px', cursor: 'pointer' }} 
            onClick={handleQIconClick}
          />
        </Box>
        
        {renderChartSummary()}
        
        {MetricId === 12 && (
          <FormControl variant="outlined" fullWidth sx={{ mb: 2, mt: 2 }}>
            <InputLabel id="item-select-label" sx={{ 
            color: '#fff',
            '&.Mui-focused': {
              color: '#fff'  // Keep label white when focused
            }
          }}>
            Select Item
          </InputLabel>
          <Select
            labelId="item-select-label"
            value={selectedItem}
            label="Select Item"
            onChange={handleItemChange}
            variant="outlined"
            sx={{
              backgroundColor: 'transparent',
              color: 'white',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white !important'  // Force white border
              },
              '& .MuiSvgIcon-root': {
                color: 'white'
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white !important',  // Force white border on hover
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white !important',  // Force white border when focused
              },
              width: { xs: '100%', sm: '150px' },
              height: '40px',
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: '#2b155a',
                  color: 'white',
                  maxHeight: 320,
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: 'rgba(255, 255, 255, 0.1)',
                    borderRadius: '2px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'rgba(255, 255, 255, 0.3)',
                    borderRadius: '2px',
                    '&:hover': {
                      background: 'rgba(255, 255, 255, 0.4)',
                    },
                  },
                  
                  '& .MuiMenuItem-root': {
                    color: 'white',
                    fontSize:"15px",
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    },
                  },
                },
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          >
            
              {availableItems.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <Paper sx={{
          padding: "20px", backgroundColor: "rgba(255, 255, 255, 0.1)", color: "#FFFFFF", borderRadius: "15px",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)", width: "96%", height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}>
          {loading ? (
            <CircularProgress />
          ) : MetricId === 12 ? (
            renderDemandForecastChart()
          ) : MetricId === 13 ? (
            renderLineChartForMetric13()
          ) : selectedCategory ? (
            <Carousel
              animation="slide"
              navButtonsAlwaysInvisible={true} 
              // navButtonsAlwaysVisible
              autoPlay={false}
              indicators={false}
              // NavButton={({ onClick, className, style, next, prev }) => {
              //   return (
              //     <Button onClick={() => {
              //       setActiveChartIndex(next ? 0 : 1);
              //       // renderChartSummary();
              //       onClick();
              //     }} className={className} style={{
              //       ...style,
              //       backgroundColor: 'rgba(255, 255, 255, 0.3)',
              //       color: '#fff',
              //       borderRadius: '50%',
              //       minWidth: '40px',
              //       width: '40px',
              //       height: '40px',
              //       position: 'absolute',
              //       top: '50%',
              //       transform: 'translateY(-50%)',
              //       zIndex: 10,
              //       [next ? 'right' : 'left']: '10px',
              //     }}>
              //       {/* {next ? <ArrowForwardIcon /> : <ArrowBackIcon />} */}
              //     </Button>
              //   )
              // }}
              sx={{
                width: '100%',
                height: '350px',
                position: 'relative', 
              }}
            >
              {renderLineChart()}
              {/* {renderWaterfallChart()} */}
            </Carousel>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart 
                data={MetricId === 14 ? upSellBarChartData : crossSellBarChartData} 
                onClick={(data) => handleBarClick(data.activePayload[0].payload)}
                margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                <XAxis dataKey="category" 
                  tick={{ fill: "#bbbbbb", fontSize: 12, angle: -25, textAnchor: 'end' }}
                  label={{
                    value:barChartLabels.xAxis,
                    position: "insideBottom", 
                    fill: "#bbbbbb", 
                    fontSize: 14,
                    fontWeight: "bold",
                    dy: isMobile ? 26 : 20, // Adjust this value for label positioning
                  }}
                  interval={0} // Display all ticks
                  height={60}
                />
                <YAxis 
                  tick={{ fill: "#bbbbbb", fontSize: 12 }} 
                  tickFormatter={(value) => `${value}`} 
                  label={{
                    value: barChartLabels.yAxis, 
                    angle: -90, 
                    position: "insideLeft", 
                    fill: "#bbbbbb", 
                    fontSize: 14,
                    fontWeight: "bold",
                    dx: -7 // Adjust this value for label positioning
                  }}
                />
                <Tooltip cursor={{ fill: "transparent" }} content={<CustomTooltip />} />
                <Legend 
                  wrapperStyle={{ color: "#FFFFFF", paddingTop: 30, }} 
                  payload={Object.keys(MetricId === 14 ? upSellBarChartData[0] || {} : crossSellBarChartData[0] || {})
                    .filter(key => key !== 'category')
                    .map((key, index) => ({
                      value: key,
                      type: 'rect',
                      color: COLORS[index % COLORS.length]
                    }))}
                />
                {Object.keys(MetricId === 14 ? upSellBarChartData[0] || {} : crossSellBarChartData[0] || {})
                  .filter(key => key !== 'category')
                  .map((key, index) => (
                    <Bar key={key} dataKey={key} fill={COLORS[index % COLORS.length]} radius={[10, 10, 0, 0]} />
                  ))
                }
              </BarChart>
            </ResponsiveContainer>
          )}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ color: '#fff' }}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MetricPopup;